// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-category-1-jsx": () => import("./../../../src/pages/category1.jsx" /* webpackChunkName: "component---src-pages-category-1-jsx" */),
  "component---src-pages-category-2-jsx": () => import("./../../../src/pages/category2.jsx" /* webpackChunkName: "component---src-pages-category-2-jsx" */),
  "component---src-pages-category-3-jsx": () => import("./../../../src/pages/category3.jsx" /* webpackChunkName: "component---src-pages-category-3-jsx" */),
  "component---src-pages-ds-details-jsx": () => import("./../../../src/pages/ds-details.jsx" /* webpackChunkName: "component---src-pages-ds-details-jsx" */),
  "component---src-pages-ds-jsx": () => import("./../../../src/pages/ds.jsx" /* webpackChunkName: "component---src-pages-ds-jsx" */),
  "component---src-pages-high-notes-jsx": () => import("./../../../src/pages/high-notes.jsx" /* webpackChunkName: "component---src-pages-high-notes-jsx" */),
  "component---src-pages-highnotes-category-jsx": () => import("./../../../src/pages/highnotes-category.jsx" /* webpackChunkName: "component---src-pages-highnotes-category-jsx" */),
  "component---src-pages-home-jsx": () => import("./../../../src/pages/home.jsx" /* webpackChunkName: "component---src-pages-home-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-resource-details-js": () => import("./../../../src/pages/resource-details.js" /* webpackChunkName: "component---src-pages-resource-details-js" */),
  "component---src-pages-resource-jsx": () => import("./../../../src/pages/resource.jsx" /* webpackChunkName: "component---src-pages-resource-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-showall-jsx": () => import("./../../../src/pages/showall.jsx" /* webpackChunkName: "component---src-pages-showall-jsx" */),
  "component---src-pages-uma-jsx": () => import("./../../../src/pages/uma.jsx" /* webpackChunkName: "component---src-pages-uma-jsx" */),
  "component---src-pages-umbi-jsx": () => import("./../../../src/pages/umbi.jsx" /* webpackChunkName: "component---src-pages-umbi-jsx" */),
  "component---src-pages-umc-jsx": () => import("./../../../src/pages/umc.jsx" /* webpackChunkName: "component---src-pages-umc-jsx" */),
  "component---src-pages-umd-jsx": () => import("./../../../src/pages/umd.jsx" /* webpackChunkName: "component---src-pages-umd-jsx" */),
  "component---src-pages-uml-jsx": () => import("./../../../src/pages/uml.jsx" /* webpackChunkName: "component---src-pages-uml-jsx" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

